$antracite: #463F3A;
$stone: #8A817C;
$smoke: #BCB8B1;
$cashmere: #E0AFA0;
$herecomesthesun: #FFBA49;
$watermelon: #EF5B5B;

$accent-color: $cashmere;
$font-color-accent: $watermelon;

$font-primary: oswald, sans-serif;
$font-secondary: 'Petrona', serif;
$font-display: acier-bat-gris, sans-serif;
$font-display-small-screens: rift, sans-serif;

$breakpoint-medium: 500px;
$breakpoint-large: 1000px;