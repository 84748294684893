.Menu {
    ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        padding: 1rem 2rem;
        margin: 0;

        @media screen and (min-width: $breakpoint-large) {
            justify-content: flex-end;
        }
    }

    li {
        padding: 1rem 0.5rem;

        &.contrast-icon {
            margin-left: auto;
            margin-right: 0;
            cursor: pointer;
        }
        @media screen and (min-width: $breakpoint-medium) {
            padding: 0;
            margin-right: 1rem;
        }
    }

    a {
        color: var(--font-color-primary);
        text-decoration: none;

        &:hover,
        &:active,
        &:visited,
        &.active {
            color: var(--font-color-primary);
        }
    }
}
