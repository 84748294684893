.highlight-container,
.highlight {
    position: relative;
    color: white;
}

.highlight-container {
    display: inline-block;
}
.highlight-container:before {
    content: " ";
    position: absolute;
    height: 120%;
    width: 104%;
    background: #ef5b5b;
    border-radius: 27% 28% 35% 35%;
    margin-left: -0.2rem;
    transform: rotate(-2deg);
}
