@import "variables";

@import "components/Highlight/Highlight";
@import "components/Home/Home";
@import "components/Menu/Menu";
@import "components/NotFound/NotFound";
@import "components/Photography/Photography";

:root {
    font-size: 20px;
    --page-background-color: #{$herecomesthesun};
    --font-color-primary: white;
}

body,
html {
    background-color: var(--page-background-color);
    color: var(--font-color-primary);
    font-family: $font-primary;
    font-weight: 300;
    font-size: 1.5rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1 {
    font-family: $font-display-small-screens;
    font-weight: 800;
    color: var(--font-color-primary);
    font-weight: 400;
    font-size: 2.25rem;
    margin-top: 0;
    margin-bottom: 3.75rem;
}

a {
    color: var(--font-color-primary);
    text-decoration-color: $font-color-accent;

    &:visited {
        color: var(--font-color-primary);
        text-decoration-color: $font-color-accent;
    }

    &:hover,
    &:active,
    &.active {
        color: $font-color-accent;
        text-decoration-color: $font-color-accent;
        font-weight: 500;
    }
}

p {
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;

    @media screen and (min-width: $breakpoint-large) {
        font-size: 2.5rem;
    }
}
