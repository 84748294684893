.Home {
    padding: 3.75rem 2.5rem;

    h1 {
        word-break: break-all;
        font-size: 15vw;
        color: var(--font-color-primary);

        &:first-child {
            margin-bottom: 0;
        }

        @media screen and (min-width: $breakpoint-medium) {
            font-family: $font-display;
            font-weight: 400;
        }
    }
}
